.social_connection {
    display: flex;
    align-items: center;
    width: fit-content;

    .socials {
        margin: 3rem 0.5rem;
        cursor: pointer;
        padding: 0.3rem;
        border-radius: 0.2rem;

        &:hover {
            background-color: rgba($color: #000000, $alpha: 0.7);

        }
    }
}