.side_menu_box {

    --BOX_WIDTH: 400px;

    @include max-break-point(mobile-phone) {
        --BOX_WIDTH: 80%;
    }

    hr {
        margin: 15px 0;
        width: var(--BOX_WIDTH);
        opacity: 0.7;
        background: linear-gradient(180deg, #F5F5F7 -7.14%, #949599 100%);
    }

    .toggle_component {
        color: white;
        margin-top: 5px;
        display: flex;
        justify-content: space-between;
        min-width: var(--BOX_WIDTH);
        max-width: var(--BOX_WIDTH);
        align-items: center;

        .title {
            font-size: 1.25rem;
            font-weight: 600;
        }

        .subDesc {
            font-size: 0.875rem;
            margin-top: 0.313rem;
            color: #e8e8e8;

        }



        &.zone_toggle,
        &.filter_land_toggle {
            display: block;

            .topBox {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 0.625rem;
            }

            .ads_cta {
                font-size: 0.813rem;
                display: flex;
                align-items: center;
                color: #9BA5B7;
                cursor: pointer;

                .down {
                    margin-left: 0.313rem;
                }

                &:hover {
                    color: #aab4c7;

                    .down {
                        color: #aab4c7;
                    }

                }
            }

            .advance_setting {
                .zone_category_toggle {
                    margin: 0.75rem 0px;
                    display: flex;
                    width: 100%;
                    align-items: center;
                    justify-content: space-between;
                }

                transition: 0.9s;
                padding-top: 0.875rem;
                margin-top: 0.875rem;
                border-top: 1px solid rgba($color: #ffffff, $alpha: 0.2);
                width: inherit;
                align-items: center;
                overflow: hidden;

                &.close {
                    height: 0px;
                    padding-top: 0;
                    border-top: none;
                    margin-top: 0;
                }
            }
        }

        &.filter_land_toggle {


            .advance_setting {
                padding-top: 0;

                .category_box {
                    font-family: Lintel;

                    .category_title {
                        margin-top: 1.5rem;
                        font-size: 1rem;
                        font-size: 600;
                        text-transform: uppercase;
                    }

                    .category_list {
                        margin-top: 0.3rem;
                        display: block;
                        column-count: 2;
                        font-size: 0.9rem;
                        column-gap: 1rem;

                        .category_item {
                            display: flex;
                            cursor: pointer;
                            padding-top: 0.5rem;
                            justify-content: space-between;
                            align-items: center;
                            color: rgba($color: #ffffff, $alpha: 0.4);




                            &.selected {
                                text-decoration-line: underline;
                                color: rgba($color: #ffffff, $alpha: 0.8);

                            }

                            &:hover {
                                color: rgba($color: #ffffff, $alpha: 1);

                            }
                        }
                    }
                }
            }

        }

        &.wallet_toggle {
            display: flex;

            .disconnect {
                font-size: 0.7rem;
                text-decoration: none;
                cursor: pointer;
                color: #e8e8e8;


                &:hover {
                    text-decoration: underline;
                    color: red;
                }
            }

            .address {
                font-size: 0.8rem;
                text-decoration: none;
                color: #AA9B46;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }

            .infoBox {
                width: 70%;
            }

        }
    }
}