.rps_lobby_container {
    width: inherit;
    height: inherit;
    position: relative;
    padding: 20px;

    .lobby_rps {
        overflow: scroll;
        position: relative;

        &.dialog_mode {
            filter: blur(5px);

        }

        #lobby_png {
            position: relative;
            width: 681px;
            height: 413px;
        }

        .character_box {
            position: absolute;
            top: 210px;
            left: 150px;
            display: flex;
            align-items: center;
            flex-direction: column;

            .pop_head {
                width: fit-content;
                height: fit-content;
                padding: 5px;
                font-size: 12px;
                border-radius: 5px;
                position: relative;
                color: whitesmoke;
                background-color: rgb(91, 0, 118);
                border: 1px solid #DDBF62;

                &::after {
                    content: "";
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    margin-left: -5px;
                    border-width: 5px;
                    border-style: solid;
                    border-color: #DDBF62 transparent transparent transparent;
                }
            }

            .character {
                width: 80px;
                cursor: pointer;
                filter: drop-shadow(0 0 0.2rem rgb(252, 252, 252));
                height: auto;

                &:hover {
                    filter: drop-shadow(0 0 0.2rem rgb(59, 230, 133));

                }
            }
        }

        #player {
            left: 300px;

            .character {
                width: 80px;
                cursor: default;
                filter: drop-shadow(0 0 0.1rem rgb(252, 252, 252));
                height: auto;


            }
        }


    }
}