.header_logo {
    position: absolute;
    top: 3.125rem;
    left: 3.125rem;
    z-index: 15;
    display: flex;
    align-items: center;

    .header_flex_box {
        display: flex;
        align-items: center;


        .logos {
            display: flex;
            opacity: 0.5;
            align-items: center;

            img {
                margin-right: 0.313rem;
            }

            div {
                color: white;
                font-size: 1.5rem;
                letter-spacing: 0.125rem;
                font-family: 'Big Shoulders Display', cursive;
                white-space: nowrap;
                text-shadow: -0.4px -0.4px 0 white, 0.4px -0.4px 0 white, -0.4px 0.4px 0 white, 0.4px 0.4px 0 white;
            }
        }

        .mobile_header {
            display: none;
        }

    }

    @include max-break-point(mobile-phone) {
        left: 0;
        top: 2rem;
        width: 100vw;
        justify-content: space-around;
        opacity: 1 !important;

        .header_flex_box {
            width: 95%;
            align-items: center;
            justify-content: space-between;

            .mobile_header {
                display: block;
                background-color: black;
                width: 40px;
                height: 40px;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: space-around;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    z-index: -1;
                    margin: -0.188rem;
                    border-radius: inherit;
                    background: linear-gradient(180deg, #DDBF62 -7.14%, #887635 100%);
                }
            }

            .logos {
                img {
                    width: 12rem;
                    height: auto;
                }

                div {
                    font-size: 1rem;
                }
            }
        }
    }
}