@import url(https://fonts.googleapis.com/css?family=Lato:400,700,900);

$border-radius-size: 14px;
$barbarian: white;
$archer: #EE5487;
$giant: #F6901A;
$goblin: #82BB30;
$wizard: #4FACFF;

.rps_reward_distribution {
    padding: 20px;
    color: white;
    --title-black-gradient: linear-gradient(180deg, #12191c 0%, #0A0E10 100%);
    --default-panel-divider-outer-side-color: #222;

    --default-panel-divider-outer-side-color: linear-gradient(180deg, #555 0%, #333 100%);

    .leader_board_season {
        background: #0a0e10;
        border: 1px solid #ddbf62;
        padding: 15px 0;
        width: 99%;
        text-align: center;

        .round_title {
            margin: 0;
            font-size: 1.2rem;
            color: rgba($color: #ffff, $alpha: 0.8);
            margin-bottom: 7px;
        }

        .round_desc {
            font-size: 0.9rem;
            margin: 5px 0;
            color: rgba($color: #ffff, $alpha: 0.5);
        }

        .round_timer {
            color: rgba($color: #ffff, $alpha: 0.9);
            font-size: 0.8rem;
        }

        &.general_stats {
            // background: #0A0E10;
            // background: linear-gradient(180deg, #DDBF62 -7.14%, #887635 100%);
            border: none;
            display: flex;
            justify-content: space-around;
            align-items: center;


            div {
                align-items: center;
                display: flex;

                .icon {
                    margin: 0px 5px;
                }
            }

            background: #0A0E10;
            border: none;
            border-bottom: 1px solid #485C65;
            border: 1px solid #1a2022;
            // background: linear-gradient(180deg, #1d364344 0%, #0b161ca2 100%);
        }
    }



    .rps_reward_distribution_hallOfFame {


        background-size: cover;
        font: 14px/20px "Lato", Arial, sans-serif;
        color: #9E9E9E;

        .slide-container {
            margin: auto;
            width: 100%;
            text-align: center;
            background: #0A0E10;

            .floor {
                width: 100%;
                background-color: rgb(64, 64, 64);
                height: 30px;
                border-bottom-left-radius: $border-radius-size;
                border-bottom-right-radius: $border-radius-size;
            }
        }

        .wrapper {
            margin-top: 40px;
            display: flex;
            align-items: center;
            margin-bottom: 40px;
            background-color: red;
            width: 100%;
            border-radius: $border-radius-size + 5;

            &:focus {
                outline: 0;
            }
        }



        .clash-card {
            width: 100%;
            display: flex;
            align-items: center;
            background: linear-gradient(180deg, #1d364344 0%, #0b161ca2 100%);
            margin: auto;
            border-radius: $border-radius-size + 5;
            position: relative;
            text-align: center;
            box-shadow: -1px 15px 30px -12px black;
            z-index: 9999;



            .hof_detail {
                height: 100%;
            }
        }

        .clash-card__image {
            position: relative;
            width: 120px;
            height: 100px;
            margin-bottom: 35px;

        }

        .clash-card__image--barbarian {

            // background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/barbarian-bg.jpg');
            img {
                width: 120px;
                position: absolute;
                // transform: scaleX(-1);
                top: -35px;
                left: 0px;
            }
        }



        .clash-card__level {
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 700;
            margin-bottom: 3px;
        }

        .clash-card__level--barbarian {
            color: $barbarian;
        }



        .clash-card__unit-name {
            font-size: 26px;
            color: white;
            font-weight: 900;
            margin-bottom: 5px;
        }

        .clash-card__unit-description {
            padding: 20px;
            margin-bottom: 10px;
        }

        .clash-card__unit-stats--barbarian {
            background: $barbarian;

            .one-third {
                border-right: 1px solid #BD7C2F;
            }
        }

        .clash-card__unit-stats--archer {
            background: $archer;

            .one-third {
                border-right: 1px solid #D04976;
            }
        }

        .clash-card__unit-stats--giant {
            background: $giant;

            .one-third {
                border-right: 1px solid darken($giant, 8%);
            }
        }

        .clash-card__unit-stats--goblin {
            background: $goblin;

            .one-third {
                border-right: 1px solid darken($goblin, 6%);
            }
        }

        .clash-card__unit-stats--wizard {
            background: $wizard;

            .one-third {
                border-right: 1px solid darken($wizard, 6%);
            }
        }

        .clash-card__unit-stats {

            color: white;
            font-weight: 700;
            border-bottom-left-radius: $border-radius-size;
            border-bottom-right-radius: $border-radius-size;

            .one-third {
                width: 33%;
                float: left;
                padding: 20px 15px;
            }

            sup {
                position: absolute;
                bottom: 4px;
                font-size: 45%;
                margin-left: 2px;
            }

            .stat {
                position: relative;
                font-size: 24px;
                margin-bottom: 10px;
            }

            .stat-value {
                text-transform: uppercase;
                font-weight: 400;
                font-size: 12px;
            }

            .no-border {
                border-right: none;
            }
        }

        .clearfix:after {
            visibility: hidden;
            display: block;
            font-size: 0;
            content: " ";
            clear: both;
            height: 0;
        }

        .slick-prev {
            left: 100px;
            z-index: 999;
        }

        .slick-next {
            right: 100px;
            z-index: 999;
        }
    }
}

.round_table {
    width: 99%;
    margin-bottom: 30px;

    table {
        border-collapse: collapse;
        width: inherit;
        border-collapse: collapse;
        margin: 0px auto;
        backdrop-filter: blur(10px);
        border-radius: 5px;

        thead {
            background: var(--title-black-gradient);

            th {
                padding: 5px;
                font-size: 0.9rem;

            }
        }

        tbody {
            tr {
                background-color: #333;
                border-bottom-color: #222;
                font-size: 0.8rem;

            }

            td {
                padding: 5px;
                text-align: center;
                border-left: 2px solid;
                font-family: Segoe UI, Frutiger, Frutiger Linotype, Helvetica Neue, Arial, sans-serif;
                border-left-color: #ccc;
                border-left-color: #222;

                &.unclaimed {
                    text-transform: uppercase;
                    color: rgb(255, 203, 203);
                }

                &.claimed {
                    text-transform: uppercase;
                    color: rgb(203, 255, 212);
                }

                button.primary {
                    width: 100%;
                    text-align: center !important;
                    display: flex;
                    align-items: center;
                    padding-left: 10px;
                }
            }
        }
    }
}

@import url(https://fonts.googleapis.com/css2?family=Ewert&family=Ultra);

.leader_board_hof {
    border-radius: 10px;
    overflow: scroll;
    width: 100%;
    // display: flex;
    height: fit-content;
    margin: 10px .6rem;
    display: flex;
    align-items: center;




    .poster {
        background-image: url("https://dimmageiras.github.io/101-computing-wanted-poster-css-task/images/parchment.jpg");
        background-position: center;
        font-family: "Ultra", serif;
        background-repeat: no-repeat;
        min-width: 200px;
        border-radius: 5px;
        background-size: cover;
        box-shadow: .125rem .25rem .5rem .1875rem #000;
        box-sizing: border-box;
        color: #291200;
        text-align: center;
        padding: 10px;
        margin: 0px 10px;
        max-height: 290px;

        .name {
            font-size: 16px;
            margin: 5px 0px;

            &:hover {
                text-decoration: underline;
            }
        }

        .require {
            font: 14px/20px "Lato", Arial, sans-serif;
            font-size: 12px;
            font-weight: bold;
        }

        h1 {
            font-family: "Ewert", cursive;
            font-size: 22px;
            font-weight: 600;
            font-weight: normal;
            text-transform: uppercase;
            margin: 5px 0px !important;


        }

        h2 {
            font-size: 12px;
            font-weight: 300;
            color: rgba($color: #291200, $alpha: 0.8);
            margin: 0 !important;
        }

        u {
            font-size: 10px;
            text-decoration-line: underline;
            text-decoration-style: double;
            color: rgba($color: #291200, $alpha: 0.8);
            margin: 0 !important;
        }
    }

    .subheading {
        font-size: 14px;
        margin: none;

    }

    .mugshot {
        background-image: url("https://d2l8dezwbqkb9.cloudfront.net/png/2041.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border: .125rem solid #291200;
        display: inline-block;
        filter: sepia(60%);
        height: 60px;
        opacity: .9;
        padding: .625rem;
        width: 50px;
    }



    // @media only screen and (max-width: 30rem) {
    //     body {
    //         min-height: 35.025rem;
    //         min-width: 100%;
    //     }

    //     main {
    //         height: auto;
    //         margin: 5.5rem auto;
    //         min-height: 35.025rem;
    //         width: auto;
    //     }

    //     .poster {
    //         background-size: 20.438rem 32.625rem;
    //         height: 32.625rem;
    //         width: 20.438rem;
    //     }

    //     .mugshot {
    //         height: 13.01rem;
    //         width: 11.92rem;
    //     }
    // }
}