.open_game_battle_field {
    width: 100%;
    height: 280px;
    margin: 10px 0px;
    position: relative;
    margin-bottom: 0px;
    text-align: center;
    overflow: hidden;
    color: white;
    // border: 1px solid #485C65;
    border-radius: 1px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), url('../../../assets/mini_games/rps/battlefield.png'), black 0px -84.382px / 100% 162.597% no-repeat;
    display: flex;
    background-size: cover;
    align-items: center;
    justify-content: space-around;
    background-position: 100% 80%;

    .sucess_open_game {
        position: absolute;
        color: white;
        z-index: 9;
        width: 100%;
        position: absolute;
        text-align: center;
        font-weight: bold;
        padding: 10px 0px;
        bottom: 0;
        left: 0;
        border: 1px solid #078F18;
        background: linear-gradient(169deg, #014200 -52.93%, rgba(0, 152, 25, 1) 34.85%, rgba(8, 30, 0, 0.5) 139.92%);
    }

    .open_rps_modal {
        z-index: 10;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        backdrop-filter: blur(10px);
        display: flex;
        background-color: rgba(0, 0, 0, 0.50);
        align-items: center;
        justify-content: space-around;
        left: 0;
    }

    &.game_selected {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.70) 100%), url('../../../assets/mini_games/rps/battlefield.png'), black 0px -84.382px / 100% 162.597% no-repeat;
        background-size: cover;
        background-position: 100% 80%;

    }

    &.vibrate {
        /* Start the shake animation and make the animation last for 0.5 seconds */
        animation: vibrate 0.5s;

        /* When the animation is finished, start again */
        animation-iteration-count: 1;

        @keyframes vibrate {
            0% {
                transform: rotate(0deg);
            }

            10% {
                transform: rotate(-1deg);
            }

            20% {
                transform: rotate(1deg);
            }

            30% {
                transform: rotate(0deg);
            }

            40% {
                transform: rotate(1deg);
            }

            50% {
                transform: rotate(-1deg);
            }

            60% {
                transform: rotate(0deg);
            }

            70% {
                transform: rotate(-1deg);
            }

            80% {
                transform: rotate(1deg);
            }

            90% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(-1deg);
            }
        }
    }

    &.won {
        border: 1px solid #005f0da1;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    &.draw {
        border: 1px solid #8f6d07;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    &.lost {
        border: 1px solid #880000;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .empty_slot_player {
        line-height: 150%;
        letter-spacing: 0.5px;
    }

    .field {
        width: 250px;
        z-index: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;

        @include max-break-point(mobile-phone) {

            width: 100px;

        }

        img.cym_vs {
            width: 120px;
            height: auto;

            @include max-break-point(mobile-phone) {

                width: 80px;

            }
        }

        .choose_your_moves {
            background-color: rgba(0, 0, 0, 0.80);
            height: 200%;
            display: flex;
            align-items: center;
            justify-content: space-around;
            border-radius: 100%;

            .connect_wallet_rps,
            .mid {
                margin: 0px 30px;
            }

            .cym_title {
                font-weight: bold;
                margin-bottom: 24px;
                font-size: 18px;
            }

            .selected_wager {
                width: fit-content;
                margin: 0 auto;
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                img {
                    width: 20px;
                    height: auto;
                }

                div {
                    font-size: 1.2rem;
                    font-weight: bold;
                    margin: 0 5px;
                }
            }

            .hand_list_cym {
                margin: 15px 0;
                display: flex;
                align-items: center;

            }

            .cym_buttom_cta {
                display: flex;
                align-items: center;
                justify-content: space-evenly;

                .cym_play {
                    border-radius: 3px;
                    border: 1px solid #EFF17F;
                    background: #4F174D;
                    font-size: 18px;
                    cursor: pointer;
                    font-weight: bold;
                    padding: 8px 15px;
                    font-family: Big Shoulders Display;
                    backdrop-filter: blur(30.5px);

                    &:hover {
                        background: #420f40;
                    }

                }

                .cym_chicken_out {
                    font-weight: 600;
                    border-radius: 3px;
                    text-decoration: underline;
                    // border: 1px solid #ffe9eb;
                    background: transparent;
                    font-size: 18px;
                    cursor: pointer;
                    padding: 8px 15px;
                    font-family: Big Shoulders Display;
                }
            }
        }

        .battle_loading {
            display: flex;
            transition: all 0.1s;
            align-items: center;

            .battle_vs {
                width: auto;
                height: 60px;
            }

            @keyframes handShake {

                0%,
                100% {
                    transform: rotate(-90deg) translateY(20px);
                }

                50% {
                    transform: rotate(-100deg) translateY(5px);
                }
            }

            @keyframes handShake2 {

                0%,
                100% {
                    transform: rotateY(90deg) rotate(10deg);
                }

                50% {
                    transform: rotateY(90deg) rotate(-10deg);
                }
            }

            .you_hand {
                transform: rotate(90deg) !important;
                width: 70px;
                height: 70px;
                overflow: hidden;



                .hand {
                    width: 110px;
                    height: auto;
                    filter: grayscale(0);
                    left: -50px;
                    animation: handShake 1s infinite;
                }


            }

            .opponent_hand {
                transform: rotate(-90deg) scaleX((-1)) !important;
                width: 70px;
                height: 70px;
                overflow: hidden;



                .hand {
                    width: 110px;
                    height: auto;
                    filter: grayscale(0);
                    left: -50px;
                    animation: handShake 1s infinite;
                }
            }
        }

        .battle_result {
            display: flex;
            transition: all 0.1s;
            align-items: center;

            .battle_vs {
                width: auto;
                height: 60px;
                margin-top: -110px;
            }


            .you_hand,
            .opponent_hand {
                transform: rotate(90deg);
                width: 70px;
                height: 70px;

                &.won {
                    border: 2px solid #48B721;
                    background: #09901978;

                }

                &.lost {
                    border: 2px solid #B74E21;
                    background: #B20101;
                }

                &.draw {
                    border: 2px solid rgba(183, 78, 33, 0.803);
                    background: #8f6d07;
                }

                .hand {
                    width: 110px;
                    height: auto;
                    filter: grayscale(0);
                    left: -30px;
                    bottom: 15px;

                }


            }

            .opponent_hand {
                transform: rotate(-90deg) scaleX((-1));

            }
        }

    }

    .you,
    .opponent {
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
        justify-content: space-around;

        .icons_play_section {
            position: absolute;
            bottom: 10px;
            left: 0;

            .message_icon {
                position: relative;
            }

            .message_icon {
                font-size: 1.5rem;
            }
        }

        p.name_opponent {
            font-weight: bold;
            width: 120px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 18px;
            font-weight: bold;
            background-color: rgba(0, 0, 0, 0.70);
            padding: 2px 0;
        }

        img {
            width: auto;
            height: 90%;

            @include max-break-point(mobile-phone) {
                height: auto;
                width: 120px;

            }

            filter: drop-shadow(0 0 0.2rem rgb(255, 217, 0));


        }
    }

    .opponent {
        img {
            transform: scaleX(-1);
            filter: drop-shadow(0 0 0.2rem rgb(255, 71, 71));

        }
    }

    &.batle_playing_modal {
        height: inherit;
        position: relative;





        .you,
        .opponent {



            p {
                font-size: 20px;
                max-width: 180px;
                padding: 4px 6px;
                font-weight: bold;
                background-color: rgba(0, 0, 0, 0.70);


            }


        }

        .you {
            animation-delay: 1s;
            animation-duration: 2s;
        }

        .opponent {
            animation-delay: 3s;
            animation-duration: 2s;
        }

        .battle_vs,
        .you_hand,
        .opponent_hand {
            animation-delay: 4s;
            animation-duration: 3s;
        }

    }

}

.battle_field_close_button {
    display: flex;
    padding: 8px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 3px;
    border: 1px solid #EFF17F;
    background: #4F174D;
    backdrop-filter: blur(30.5px);
    color: white;
    width: fit-content;
    cursor: pointer;
    margin: 10px auto;

    &:disabled {
        background: #565556;
        cursor: default;
    }
}

.battle_notification {
    width: 100%;
    text-align: center;
    padding: 3px 0px;
    border-radius: 0px 0px 4px 4px;
    color: #F5EEEE;
    font-family: Manrope;
    font-size: 14px;
    display: none;
    font-style: normal;
    font-weight: bold;
    line-height: 18px;

    &.draw {
        background: linear-gradient(169deg, #8f6d07 -52.93%, rgba(178, 194, 0, 0.74) 34.85%, rgba(30, 28, 0, 0.46) 139.92%);
        display: block;
        border: 1px solid #8f6d07;
    }

    &.won {
        display: block;
        border: 1px solid #8f6d07;
        border: 1px solid #078F18;
        background: linear-gradient(169deg, #014200 -52.93%, rgba(0, 194, 31, 0.74) 34.85%, rgba(8, 30, 0, 0.46) 139.92%);

    }

    &.lost {
        border: 1px solid #C20000;
        display: block;
        background: linear-gradient(169deg, #4A0000 -52.93%, #C20000 34.85%, #420000 139.92%);
    }


}