$dollarNum: 80;


[class|="token"] {
    position: absolute;
}

.makeItRain {
    position: absolute;
    min-height: 100vh;
    width: 100%;
}



// DOLLAR CONSTRAINTS
// width: 40 – 80px
// height: 40 – 80px
// animation-duration: 4 – 8s
// animation-iteration-count: 30 – 60
// transform-origin: right -15px – -45px
// opacity: 0.3 – 0.9
// animation-delay: 0s – 3.5s
// animation-timing-function: ease-in, ease-out, linear

/* dollars */
@for $i from 1 through $dollarNum {
    $fallType: (
        ease-in,
        ease-out,
        linear
    );
$paperSize: random(5) * 10 + 30;
$left: random(100);

.token-#{$i} {
    width: #{$paperSize}px;
    height: #{$paperSize}px;
    top: -200px;
    left: unquote($left + "%");
    opacity: random(7) / 10 + 0.5;
    display: inline-block;
    z-index: random(5) + 3;
    animation: make-it-rain-#{$i} unquote(3 + random(8) + "s") unquote(random(8) - 1 + "s") infinite nth($fallType, random(3));
    animation-iteration-count: random(31) + 29;
    transform-origin: right unquote(random(3) * -15 + "px");

    &.CROWN {
        background-image: url('../../../../assets/icons/crown-logo.svg');

    }

    &.ICX {
        background-image: url('../../../../assets/icons/iconex.svg');

    }

    background-size: contain;
}

@keyframes make-it-rain-#{$i} {
    0% {
        transform: translate3d(0, 0, 0) rotate(-90deg) scale(0.6);
    }

    100% {
        transform: translate3d(35px, 1200px, 0px) rotate(360deg) scale(0.6);
        top: 110%;
    }
}
}