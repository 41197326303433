.rps_leader_board {
    color: rgba($color: #ffffff, $alpha: 0.9);
    padding-bottom: 130px;

    .usd_dollar {
        font-size: 0.8rem;
        margin: 0px 5px;
        font-weight: 400;

        &::before {
            content: "(";


        }

        &::after {
            content: ")";


        }
    }

    .leader_board_table {
        display: flex;
        align-items: center;
        flex-direction: column;

        .gp_list {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            overflow-x: scroll;

            @include max-break-point(tablet-landscape) {
                grid-template-columns: repeat(2, 1fr);
            }

            @include max-break-point(mobile-phone) {
                grid-template-columns: repeat(1, 1fr);
            }
        }

        .rps_general_stats {
            display: flex;
            align-items: center;
            padding: 15px;

            .rps_general_stats_icon {
                width: auto;
                height: 45px;
                border: none;
                margin-right: 10px;
                filter: grayscale(100%);

                &.crown {
                    width: 30px;
                }
            }

            // img {

            // }

            .rps_g_s_desc {
                color: rgba($color: #ffff, $alpha: 0.7);

                .rps_g_s_t {}

                .rps_g_s_val {
                    color: rgba($color: #ffff, $alpha: 0.6);
                    font-size: 1.2rem;
                    font-family: Segoe UI, Frutiger, Frutiger Linotype, Helvetica Neue, Arial, sans-serif;
                    font-weight: bold;
                    margin-top: 3px;

                }
            }
        }

        .leader_board_notice {
            background: #0A0E10;
            border: 1px solid #242d30;
            padding: 4px 0;
            width: 80%;
            text-align: center;
        }

        .leader_board_season {
            background: #0A0E10;
            border: 1px solid #242d30;
            padding: 15px 0;
            width: 99%;
            text-align: center;

            .round_title {
                margin: 0;
                font-size: 1.2rem;
                color: rgba($color: #ffff, $alpha: 0.8);
                margin-bottom: 7px;
            }

            .round_desc {
                font-size: 0.9rem;
                margin: 5px 0;
                color: rgba($color: #ffff, $alpha: 0.5);
            }

            .round_timer {
                color: rgba($color: #ffff, $alpha: 0.9);
                font-size: 0.8rem;
            }

            &.general_stats {
                background: #0A0E10;
                border: 1px solid #1a2022;
            }


        }



        table {
            width: 90%;
            border: 1px solid #485c6593;
            border-top: none;
            background-color: rgba($color: #151718, $alpha: 0.8);
            text-align: center;

            .reward_value {

                img {
                    width: 12px;
                    height: auto;
                    margin: 0px 5px;
                }
            }

            td {
                padding: 10px 0;
            }

            tbody {
                .user {
                    background-color: rgba($color: #ffffff, $alpha: 0.1);

                }

                .pos_0 {
                    background-color: rgba($color: #d4af37, $alpha: 0.3);
                    font-weight: bold;

                }

                .pos_1 {
                    background-color: rgba($color: #e5e4e2, $alpha: 0.2);
                    font-weight: bold;

                }

                .pos_2 {
                    background-color: rgba($color: #cd7f32, $alpha: 0.1);
                    font-weight: bold;

                }



                td {
                    font-family: Segoe UI, Frutiger, Frutiger Linotype, Helvetica Neue, Arial, sans-serif;
                    color: rgba($color: #ffffff, $alpha: 0.8);
                    font-size: 0.9rem;

                    .unit {
                        font-size: 0.7rem;

                    }
                }

                .name_value {
                    max-width: 50px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    text-align: left;
                    padding: 5px;
                }
            }

            .table_stats {
                font-size: 0.75rem;
            }

        }

        .round_table {
            table {
                tbody {
                    background-color: rgba($color: #151718, $alpha: 0.9);

                    tr {
                        border: 1px solid #222;

                        td {
                            &.text_left {
                                padding-left: 10px;
                                text-align: left;

                            }
                        }
                    }
                }
            }
        }
    }
}