/* The switch - the box around the slider */
.switch {
    position: relative;
    box-sizing: border-box;



    cursor: pointer;
    display: flex;
    z-index: 2;
    align-items: center;
    background: linear-gradient(180deg, #DDBF62 -7.14%, #887635 100%);
    border-radius: 2.125rem;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    input:checked+.slider {
        background: linear-gradient(180deg, #DDBF62 -7.14%, #887635 100%);
        transition: .4s;

    }

    input:focus+.slider {
        transition: .4s;
        box-shadow: 0 0 1px #2196F3;
    }

    input:checked+.slider:before {
        -webkit-transform: translateX(1.625rem);
        -ms-transform: translateX(1.625rem);
        transform: translateX(1.625rem);
    }

    /* The slider */
    .slider {
        top: 0;
        position: absolute;
        right: 0;
        bottom: 0;
        border-radius: 2.125rem;
        z-index: 0;
        left: 0;
        background-color: #242129;
        border-radius: inherit;
        -webkit-transition: .4s;
        margin: 1px;
        transition: .4s;

        &::before {
            border-radius: 50%;
            position: absolute;
            content: "";
            height: 1.125rem;
            width: 1.125rem;
            left: 0.25rem;
            bottom: 0.25rem;
            z-index: 1;
            background: linear-gradient(180deg, #F5F5F7 -7.14%, #949599 100%);
            -webkit-transition: .4s;
            transition: .4s;
        }



    }

    &.large {
        width: 3.25rem;
        height: 1.625rem;
    }

    &.medium {
        width: 3rem;
        height: 1.25rem;

        .slider {
            border-radius: 2.125rem;

            &::before {
                height: 0.75rem;
                width: 0.75rem;
            }



        }

    }

    &.disabled {
        background: linear-gradient(180deg, grey -7.14%, #3f3f3f 100%);

        .slider {


            &::before {

                background: linear-gradient(180deg, #979797 -7.14%, #949599 100%);

            }



        }
    }

}

/* Hide default HTML checkbox */
.switch .slider:before {}