.dropdown {
    width: fit-content;
    box-shadow: 0 10px 25px rgba(0, 0, 0, .1);
    border: 1px solid var(--updated-gradient-gold, #DDBF62);
    background: #242129;
    width: 130px;
    position: relative;

    .dropdown-header {
        padding: 10px 15px;
        cursor: pointer;
        display: flex;
        font-size: 16px;
        align-items: center;
        justify-content: space-between;

        img {
            width: auto;
            height: 15px;
            margin-right: 10px;
        }
    }

    .dropdown-body {
        padding: 5px;
        width: 90%;
        position: absolute;
        background: #242129;
        border: 1px solid var(--updated-gradient-gold, #DDBF62);
        border-top: 1px solid #E5E8EC;
        display: none;
        z-index: 2;
    }

    .dropdown-body.open {
        display: block;
    }

    .dropdown-item {
        padding: 10px;
    }

    .dropdown-item:hover {
        cursor: pointer;
    }

    .dropdown-item-dot {
        opacity: 0;
        color: #91A5BE;
        transition: all .2s ease-in-out;
    }

    .dropdown-item-dot.selected {
        opacity: 1;
    }

    .icon {
        font-size: 13px;
        color: white;
        transform: rotate(0deg);
        transition: all .2s ease-in-out;
    }

    .icon.open {
        transform: rotate(180deg);
    }
}