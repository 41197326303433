.mini_game_modal {
    &.normal.overlay {
        height: fit-content;
        width: 100vw;
        top: 10rem;
        left: inherit !important;
        right: 3rem !important;
        border-radius: 0;
        max-height: 80vh;

        @include max-break-point(tablet-landscape) {
            top: inherit;
            bottom: 1rem !important;
            left: 0rem !important;
        }

        @include max-break-point(mobile-phone) {}
    }

    overflow: scroll;
    transition: all .2s ease-in-out;



    .modal-title-div {
        width: 90%;

        .plain__separator {
            // display: none;
            height: 2px;
            width: inherit;
            margin: 0 auto;
            background-image: linear-gradient(to left, transparent 0%, #aa9b46 100%);
            background: linear-gradient(90deg, rgba(189, 164, 81, 0.00) -2.81%, #DCBE61 -2.8%, #AE9649 28.41%, #B39B4C 64.67%, rgba(187, 161, 80, 0.00) 93.88%);
        }

        .modal-heading-name {
            font-size: 1.3rem;
            text-align: left;
            width: inherit;
            margin-bottom: 5px;
        }
    }

}