@import url('https://fonts.googleapis.com/css?family=Pirata+One|Bilbo+Swash+Caps&display=swap');


.open_game_container {
    padding: 20px;
    margin-bottom: 60px;



}

.open_game_list {
    display: grid;
    margin: 0 auto;
    margin-top: 10px;

    width: fit-content;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
    max-height: 255px;
    min-height: 160px;
    padding: 20px 0;
    overflow-x: scroll;

    @include max-break-point(tablet-landscape) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include max-break-point(mobile-phone) {
        grid-template-columns: repeat(1, 1fr);
    }

}

.rps_open_game_item {
    max-width: 205px;
    min-width: 185px;
    position: relative;
    min-height: 50px;
    max-height: 80px;
    height: fit-content;
    padding: 8px 12px;
    cursor: pointer;
    z-index: 2;
    border: 1px solid #485C65;
    // background: #0A0E10;
    // background: linear-gradient(180deg, #192224 14.43%, #0A0E10 53.24%, #192224 91.24%);

    box-shadow: inset #ffffff24 1.5px 1.5px;
    background: linear-gradient(to bottom, #202020 0%, #141414 100%);
    text-shadow: black 1px 1px 1px;

    &.challenge {
        border: 1px solid rgba($color: #7c1919, $alpha: 0.8);
        // background: black;
        box-shadow: inset #ff000024 1.5px 1.5px;

        .open_game_pattern {
            background: linear-gradient(to bottom, rgba($color: #7c1919, $alpha: 0.7) 0%, rgba($color: #410101, $alpha: 0.5) 100%);

        }
    }

    &.friends {
        border: 1px solid #469732;
    }







    .open_game_pattern {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        overflow: hidden;
        z-index: -3;


        img {
            width: auto;
            height: 140px;
            opacity: 0.1;
            margin-right: -10px;
        }
    }

    .rps_game_card_top {
        display: flex;
        align-items: center;
        // border-radius: 4px;
        // overflow: hidden;
    }

    .message {
        color: #dbdbdb;
        margin-top: 10px;
        font-size: 12px;
    }

    // box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.25) inset, 0px -1px 0px 0px rgba(255, 255, 255, 0.25) inset;
    &.host_a_game {
        background: #1c2e36;
        background: linear-gradient(180deg, #1c2e36 15%, #17262c 53.24%, #1c2e36 91.24%);
    }

    &.open_game_item {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: space-around;
        color: #dbdbdb;
        font-size: 18px;

        &:hover {
            border: 1px solid #f0db1e9a;
            background: #1f1b25;
        }
    }

    &.disabled {
        border: 1px solid #485C65;
        background: #1C1E1F;
        cursor: default;

        &:hover {
            border: 1px solid #485C65;
            background: #1C1E1F;
        }

    }


    position: relative;

    justify-content: space-between;
    overflow: visible;

    .streak_bar {
        position: absolute;
        z-index: 3;
        bottom: -10px;
        right: 0;
        display: flex;
        align-items: center;

        .streak_fire_log {
            background-color: #534C0F;
            width: 13px;
            height: 13px;
            border-radius: 100%;
            padding: 3px;
            display: flex;
            align-items: center;
            justify-content: space-around;

            img {
                width: auto;
                height: 100%;
            }
        }
    }

    .win_streak {
        position: absolute;
        top: -160px;
        left: 0;
        opacity: 0.2;

    }

    &.selected {
        border: 1px solid var(--updated-gradient-gold, #DDBF62);
        background: #21231B;
        backdrop-filter: blur(12px);
    }

    &.not_selected:hover {
        border: 1px solid #f0db1e9a;
        background: #27232d;
    }

    .game_details {
        display: flex;
        align-items: center;
        gap: 8px;


        .player_image {
            width: 45px;
            height: 45px;
            background-color: black;
            object-fit: cover;
            object-position: 100% 0;
            border-radius: 4px;
            border: 1px solid #673F26;



        }


        .player_details {

            .player_name {
                color: #AFAFAF;
                font-size: 16px;
                width: 100%;
                min-width: 100px;
                display: flex;
                align-items: center;
                margin-bottom: 5px;

                .value {
                    white-space: nowrap;
                    overflow: hidden;
                    max-width: 130px;

                    &.has_message {
                        max-width: 98px;

                        &.is_friend {
                            max-width: 85px;
                        }

                    }

                    &.is_friend {
                        max-width: 110px;
                    }

                    text-overflow: ellipsis;
                }

                .friends_icon {
                    color: rgb(20, 156, 20);
                    font-size: 10px !important;
                    border-radius: 100%;
                    margin-left: 5px;
                }
            }

            .wager_amt {
                color: rgba(255, 255, 255, 0.80);
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                display: flex;
                align-items: center;
                /* 19.2px */
                // position: relative;

                .challenge_icon {
                    position: absolute;
                    bottom: 10px;
                    right: 5px;
                    // border: 1px solid rgba($color: #7c1919, $alpha: 0.8);
                    // background: rgba($color: #110909, $alpha: 0.8);
                    width: 40px;
                    height: 40px;
                    z-index: -1;
                    border-radius: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    // background: linear-gradient(to bottom, rgba($color: #7c1919, $alpha: 1) 0%, rgba($color: #410101, $alpha: 9) 100%);

                    .sword {
                        color: rgb(238, 207, 207);
                        color: rgb(130, 72, 72);
                        font-size: 3rem;
                    }
                }


            }
        }


        .message_icon {
            color: #dbdbdb;
            text-shadow: 2px 2px 2px #000;
            // filter: drop-shadow(0px 0px 0.5px rgb(170, 255, 211));
            font-size: 1.4rem;

        }

    }

    .currency_type {
        height: 16px;
        width: auto;
        margin-right: 5px;
    }



}