p {
    margin: 0;
}


.modal {
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 10;
    max-height: inherit;
    animation: modal-animation 0.4s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

.close-modal-btn {
    cursor: pointer;
    position: absolute;
    right: -10px;
    top: -2px;
    z-index: 9999;
}

.blur.overlay {
    position: fixed;
    z-index: 99;
    height: 100vh;
    width: 100vw;
    top: 0px;
    left: 0px;
    border-radius: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(15px);
}

.normal.overlay {
    position: fixed;
    z-index: 5;
    height: fit-content;
    width: 100vw;
    top: 7rem;
    left: 3rem;
    border-radius: 0;
    // background-color: green;
    // padding: 10px;
    max-height: 80vh;
}

.modal-secondary-seperator {
    color: #5b5f65;
    background-color: #aa9b46;
    background-image: #aa9b46;
}

.modal-secondary-seperator::after {
    background-image: #aa9b46;
}

.modal-upper-elements {
    position: relative;
}

.modal-wrapper {
    backdrop-filter: blur(5px);
    z-index: 10;
    max-width: 450px;

    &.viewer3d {
        max-width: fit-content;
    }



}

.modal-title-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* gap: 10px; */
}

.modal-heading-name {
    color: rgba($color: #ffffff, $alpha: 0.8);
    font-weight: 600;
    font-size: 15px;
    text-align: center;
    width: 400px;
    margin: 3px;
}

.normal-modal {
    border: 1px solid #aa9b46;
    margin: 5px;
    backdrop-filter: blur(6px);
    z-index: 10;
    padding: 15px;
}

.welcome-info-text {
    /* margin-left: 20px; */
}

.map-icon {
    padding: 0px 5px;
}

.welcome-info-text>ol>li {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.0025em;
    color: #dedede;
    line-height: 25px;
}

@keyframes modal-animation {
    0% {
        transform: scale(1) translateY(0px);
        opacity: 0;
        box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }

    1% {
        transform: scale(0.96) translateY(10px);
        opacity: 0;
        box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }

    100% {
        transform: scale(1) translateY(0px);
        opacity: 1;
        box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
}