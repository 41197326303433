@import url('https://fonts.googleapis.com/css2?family=Big+Shoulders+Display:wght@100;200;300;400;500;600;800;900&display=swap');

.district_name_label {
    // font-size: 30px !important;
    font-weight: 600;
    font-family: 'Big Shoulders Display', cursive;
    white-space: nowrap;
    text-shadow: -0.4px -0.4px 0 white, 0.4px -0.4px 0 white, -0.4px 0.4px 0 white, 0.4px 0.4px 0 white;
}

.leaflet-zoom-animated {
    z-index: 10 !important;
}