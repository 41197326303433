@import '../common/mixins';
@import '../common/variables';

.district_desc {
    font-size: 0.9rem;
    line-height: 140%;
    color: #C9CCD9;
    text-justify: inter-word;

}

.view_on_cft {
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    color: #00AC97;
    width: fit-content;
    text-align: right;
    width: 100%;
    margin: 0 auto;

    &.gangstabet {
        margin-top: 0.2rem;
    }

    a {
        color: rgba($color: #00bfa9, $alpha: 0.9);
        text-align: right;
        width: 100%;
        margin-left: 2px;
        text-decoration: none;

        &:hover {
            color: rgba($color: #00AC97, $alpha: 1);
            text-decoration: underline;
        }

    }

}

.owner_address {
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    color: #C9CCD9;
    width: fit-content;
    text-align: center;
    margin: 0 auto;

    &.gangstabet {
        margin-top: 0.2rem;
    }

    a {
        color: #AA9B46;
        margin-left: 2px;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }

    }

}

.property_project {
    padding: 10px;
    color: #C9CCD9;
    margin: 10px 0px;


    .property_project_title {
        font-size: 1.1rem;
        margin-bottom: 10px;

    }

    .property_project_list {
        margin: 5px 0 10px 0;

        .property_item {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 10px 5px;
            // cursor: pointer;
            border: 1px solid #485C65;
            background: #0A0E10 !important;
            justify-content: space-evenly;
            // &:hover {
            //     background: #151e22 !important;

            // }

            img {
                width: 45px;
                height: 45px;
                background-color: rgba(255, 255, 255, 0.99);
                border-radius: 5px;
            }

            .property_info {
                margin-left: 10px;
                font-family: Proxima Nova;
                font-size: 14px;


                .property_title {
                    font-size: 1rem;
                    font-weight: bold;
                    line-height: 160%;

                }

                .property_desc {
                    font-size: 0.9em;
                    margin-top: 2px;
                }
            }


        }

        &.beatdowninc {
            img {
                height: 45px;
                width: auto !important;
                background-color: transparent;

            }
        }
    }

}

.button_3d_view {
    background: linear-gradient(180deg, #DDBF62 -7.14%, #887635 100%);

    &:hover {
        background: linear-gradient(180deg, #DDBF62 -7.14%, #887635 100%) !important;
    }
}

.attribute_container {
    width: 100%;
    // display: flex;
    // align-items: center;
    // justify-content: space-around;
    // overflow: hidden;

    .attribute_container_title {
        color: #AA9B46;
        font-weight: bold;
        font-size: 1.05rem;

    }

    .attribute_container_inside {
        position: relative;
        // padding: 1rem;
        width: 100%;
        display: flex;
        flex-wrap: wrap;



        .attribute_item {
            background-color: rgba($color: #474747, $alpha: 0.4);
            width: fit-content;
            border-radius: .8rem;
            padding: 10px 15px;
            display: flex;
            margin: 0.5rem;
            flex-direction: column;
            align-items: center;
            // margin: 0.5rem;
            flex: 2;

            margin-top: 0.5rem;

            .key {
                font-size: 0.92rem;
                color: #AA9B46;
                text-align: center;
                font-weight: 500;
            }

            .value {
                margin-top: 0.2rem;
                color: #C9CCD9;
                text-align: center;
                font-size: 0.9rem;

            }

        }
    }
}

.property-profile-page {

    .property-profile-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        row-gap: 30px;
        column-gap: 48px;


    }

    .profile-left {
        display: flex;
        flex-direction: column;
        gap: 25px;
        align-items: center;

        @include max-break-point(mobile-phone) {
            gap: 0;
        }



        .property-info-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 12px;
            margin-top: -30px;

            .property-btns {
                display: flex;
                flex-direction: row;
                gap: 18px;

                .primary {
                    background: none;
                }

                .diff-viewer-btn {
                    width: 67px;
                    height: 53px;

                    &.disabled {
                        img {
                            filter: brightness(0.8) saturate(0) !important;
                        }
                    }

                    .button {
                        width: 100%;
                        height: 100%;

                        img {
                            width: 22px;
                            height: 22px;
                            filter: brightness(0);
                        }
                    }
                }
            }

            .ipfs-link {
                font: normal 500 12px/16px var(--manrope);
                letter-spacing: 0.004em;
                text-decoration: none;
            }

            .profile-owner {
                position: relative;
                font-weight: 500;
                font-size: 12px;
                color: var(--white);
                cursor: pointer;
                background-color: transparent;
                border: none;

                &.show-info::before {
                    position: absolute;
                    content: 'Copied!';
                    bottom: -6px;
                    right: -58px;
                    background: rgba(0, 0, 0, 0.6);
                    color: var(--primary-color);
                    padding: 6px;
                    border-radius: 4px;
                    z-index: 100;
                    border: 2px solid var(--primary-color);
                }
            }
        }
    }

}


.property-img-carousel-wrapper {
    &.carousel-wrapper {
        margin: 0;
    }



    .selected-carousel {
        border: 1px solid var(--primary-color);
        border-radius: 15px;
    }

    .property-img {
        // margin-left: -50px;
        display: flex;
        align-items: center;
        flex-direction: column;

        .skeleton {
            position: relative;
            z-index: 0;
        }
    }

    .carousel-selected-img {
        width: 650px;
        height: 350px;
        object-fit: contain;
        max-width: 100%;
        margin: 0px;
        margin-top: -50px;
        border-radius: 15px;

        @include max-break-point(tablet) {
            margin-left: 30px;
        }
    }

    .image-diff-view-wrapper {
        margin-top: -50px;
        margin-left: 0px;
        margin-bottom: 15px;
        justify-content: center;
        flex-wrap: wrap;
        z-index: 3;

        position: relative;
        display: flex;

        .image-diff-view {
            display: flex;
            width: 45px;
            margin: 0px 10px;
            height: 85px;
            cursor: pointer;

            border-radius: 15px;

            &:hover {
                background: rgba(71, 71, 71, 0.4);
            }

            img {
                object-fit: contain;
            }
        }
    }
}