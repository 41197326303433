.profile_picture_container {
    position: absolute;
    top: 50px;
    transition: 0.5s;
    right: 40px;
    z-index: 10;
    padding: 0;
    height: 70px;

    &.flex {
        display: flex;
        align-items: center;
    }


    .profile_desc {
        text-align: right;
        margin-right: 10px;

        h3 {
            color: #FFF;
            text-align: center;
            text-shadow: 1px 1px 0px #000;
            font-family: Big Shoulders Display;
            font-size: 24px;
            font-style: normal;
            font-weight: 900;
            line-height: 110%;
            letter-spacing: 1.2px;
            margin: 0 0 5px 0;
        }

        p {
            color: var(--color-text-primary, #E6F2FF);
            text-shadow: 1px 1px 0px #000;
            font-family: Proxima Nova;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: 0.42px;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;

            .disconnect {
                color: #FFF;
                font-size: 18px;
                margin-left: 10px;

            }



        }
    }

    .connect_button_profile {
        margin-top: 10px;
    }

    .img_profile_c {
        height: 65px;
        width: 65px;
        min-width: 65px;
        min-height: 65px;
        border: 5px solid var(--updated-gradient-gold, #DDBF62);
        overflow: hidden;

        img {
            height: 90px;
            width: 90px;
            object-fit: cover;
            object-position: 100% 0;
            transform: scaleX(-1);
            opacity: 1;
            background: #000000;
            background: #393939;
            margin-left: -15px;

            &.no_player {
                opacity: 0.5;
                background: #333137;

            }
        }
    }


}