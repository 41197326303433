.rps_find_gangster {

    .search_rps_gangster {
        padding: 20px;
        background: #0A0E10;
        border: none;
        border-bottom: 1px solid #485C65;
        border: 1px solid #1a2022;

        .search_button {
            overflow: none;
            border: none;
            padding: 10px;
            cursor: pointer;
            background: #182024;
            color: #A3A3A3;
        }

        h4 {
            color: #FFF;
            font-family: Big Shoulders Display;
            font-size: 24px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            letter-spacing: 0.4px;
            margin-bottom: 5px;
            margin-top: 5px;
        }

        p {
            color: #DEDEDE;

            font-family: Manrope;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            margin-bottom: 10px;
            line-height: normal;


        }

        input {
            border-radius: 2px;
            background: #182024;
            color: #A3A3A3;
            padding: 10px;
            width: 60%;
            outline: none;
            border: none;
            backdrop-filter: blur(6.5px);

        }
    }




}

.rps_profile_container {
    padding: 20px;
    backdrop-filter: blur(12px);
    position: relative;
    color: white;
    margin-bottom: 30px;
    background-color: rgba($color: #0A0E10, $alpha: 0.2);

    .notification_frn_added,
    .notification_frn_failed {
        width: 100%;
        position: absolute;
        text-align: center;
        top: 0;
        left: 0;
        border: 1px solid #078F18;
        background: linear-gradient(169deg, #014200 -52.93%, rgba(0, 194, 31, 0.74) 34.85%, rgba(8, 30, 0, 0.46) 139.92%);

    }

    .notification_frn_failed {
        background: linear-gradient(169deg, #4A0000 -52.93%, #C20000 34.85%, #420000 139.92%);
        border: 1px solid #C20000;

    }

    .profile_button {
        display: flex;
        align-items: center;
        justify-content: end;

        button {
            padding: 10px 15px;
        }
    }

    .title {
        margin-top: 20px;
    }
}

.rps_profile {
    display: flex;
    position: relative;



    @include max-break-point(tablet) {
        display: block;

    }



    img.gangstabet_img_rps {
        height: auto;
        width: 20%;
        min-width: 255px;
        margin-right: 10px;
        max-height: 330px;
    }

    .last_played {
        font-size: 12px;
        margin: 3px 0;
    }

    .username_addFriend {
        display: flex;
        align-items: center;

        .add_friend_icon {
            cursor: pointer;
            background-color: rgba(0, 255, 174, 0.2);
            border-radius: 100%;
            padding: 5px;
            font-size: 20px;

            &:hover {
                background-color: rgba(0, 255, 174, 0.4);

            }

        }
    }

    h1 {
        color: #FFF;
        font-family: Big Shoulders Display;
        font-size: 32px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        letter-spacing: 0.64px;
        margin-bottom: 5px;
        max-width: 90%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 5px;

    }

    .stat_list {
        display: grid;
        margin: 10px 0;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
        overflow-x: scroll;

        @include max-break-point(tablet-landscape) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include max-break-point(mobile-phone) {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    .statBox {
        display: flex;
        min-width: 150px;
        // font-family: Segoe UI, Frutiger, Frutiger Linotype, Helvetica Neue, Arial, sans-serif;
        align-items: center;
        padding: 5px 0px;

        .icon_box_rps_stat {
            background-color: #0a662a;
            border-radius: 100%;
            height: 35px !important;
            width: 35px !important;
            display: flex;
            font-size: 22px;
            align-items: center;
            justify-content: space-around;

            img {
                height: 22px !important;
                width: 22px !important;
                filter: brightness(10) contrast(10);
            }

        }

        .stat_value {
            margin-left: 5px;
            width: 80%;
            font-size: 14px;

            .stat_name {
                font-size: 12px;
                color: rgba($color: #ffffff, $alpha: 0.7);
            }
        }


    }

    .friends_icon {
        color: rgb(58, 226, 2);
    }

    p {
        display: flex;
        align-items: center;
        color: rgba($color: #ffffff, $alpha: 0.7);

        span {
            margin-right: 5px;
        }
    }

    .gangstabet_rps_stats {
        min-width: 250px;

        @include max-break-point(tablet) {
            min-width: 90%;
            margin: 0 auto;

        }
    }

    .win_strea_div {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        .win_streak_box {
            background-color: #534C0F;

            border-radius: 100%;
            height: 35px;
            width: 35px;
            display: flex;
            font-size: 22px;
            align-items: center;
            justify-content: space-around;
        }

        .win_streak_value {
            margin-left: 5px;
            font-size: 14px;
        }
    }


    .win_rate_box {
        width: 100%;
        border-radius: 2px;
        margin-top: 2px;
        background-color: rgb(116, 17, 17);
        height: 5px;

        .win_rate_bar {
            background-color: green;
            width: 90%;
            height: inherit;

        }

    }

    .rps_stat_cards_lisst {
        flex-direction: column;
        width: 80%;
        align-items: flex-start;
        gap: 2px;

        .rps_stat_card {
            border-radius: 4px;
            background: #2E2E2E;

            padding: 8px 16px;

            .rps_s_label {
                font-size: 16px;
                color: white;
                margin-bottom: 3px;

            }

            .rps_s_value {
                font-size: 14px;
                margin: 2px 0px;
                color: rgba($color: #ffffff, $alpha: 0.8);
            }

        }
    }


}

.rps_profile_their_open_game {
    display: grid;
    margin: 0 auto;
    margin-top: 10px;

    width: fit-content;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
    margin-bottom: 60px;

    @include max-break-point(tablet-landscape) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include max-break-point(mobile-phone) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.connect_wallet_rps {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;

    .connect_wallet_rps_icon {
        font-size: 48px;
    }

    h2 {
        margin-bottom: 5px;
    }

    button {
        margin: 15px 0px;
        padding: 7px 10px;
        font-size: 14px;
        font-weight: 500;
        border-radius: 4px;
        cursor: pointer;
        color: white;

        background-color: rgba($color: #06727e, $alpha: 0.2);
        border: 1px solid #06727e;
        outline: none;

        &:hover {
            background-color: rgba($color: #06727e, $alpha: 0.6);

        }
    }

    &.small {
        .connect_wallet_rps_icon {
            font-size: 28px;
        }

        h2 {
            font-size: 18px;
        }

        p {
            font-size: 14px;
        }

    }
}

.history_container {
    width: 100%;

    .history_battle_card {
        width: 100%;
        margin: 10px 0px;

        table {
            width: 100%;

            tr {
                text-shadow: white 0.2px 0.2px 0.2px;


            }

            th {
                padding-bottom: 15px;
                text-align: left;
                border-bottom: 1px solid rgb(52, 52, 52);
            }

            td {
                padding: 15px 0;

                &.time_value {
                    font-size: 0.9rem;
                }

                .friend_detail_card {
                    img {
                        width: 35px;
                        height: 35px;
                        border-radius: 3px;
                    }

                    .frn_info {

                        h3 {
                            margin: 0px;
                            margin-bottom: 1px;
                            max-width: 90px;
                            overflow: hidden;
                            font-size: 0.9rem;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            text-align: left;
                        }

                        p {
                            white-space: nowrap;
                            overflow: hidden;
                            max-width: 110px;
                            font-size: 0.8rem;
                            text-overflow: ellipsis;
                        }
                    }
                }

                .history_result {
                    .status {

                        &.LOST {
                            color: rgb(200, 0, 0);
                            text-shadow: rgb(255, 138, 138) 0.2px 0.2px 0.2px;
                        }

                        &.WON {
                            color: rgb(0, 160, 0);
                            text-shadow: rgb(88, 255, 88) 0.2px 0.2px 0.2px;
                        }

                        &.DRAW {
                            color: rgb(189, 189, 0);
                            text-shadow: white 0.2px 0.2px 0.2px;
                        }
                    }

                    .choice {
                        font-size: 0.8rem;
                        color: #9e9e9e;
                    }
                }


            }
        }
    }
}