.rps_history_table {
    color: white;
    overflow: hidden;

    .rps_history_menu {
        display: flex;
        align-items: center;
        padding: 20px;
        margin-bottom: 20px;
        border: none;
        border-bottom: 1px solid #485C65;
        background: #0A0E10;

        div {
            margin-right: 15px;

            position: relative;
            cursor: pointer;

            &:hover {
                font-weight: bold;
            }

            &.selected {
                font-weight: bold;
                border-bottom: 1px solid yellow;
            }


        }

    }

    table {
        width: 100%;
        border-collapse: collapse;
        margin: 0px auto;
        backdrop-filter: blur(10px);

    }

    .data_table {
        overflow: scroll;

    }

    /* Zebra striping */


    th {
        background: #0A0E10;
        color: white;
        font-weight: bold;
    }

    td,
    th {
        padding: 10px;
        border: 1px solid #1d1d1d;
        text-align: left;
        font-size: 16px;
        letter-spacing: 0.1px;

        .currency {
            font-size: 12px;
        }

        &.close_button {
            cursor: pointer;

            &:hover {
                text-decoration: underline;
                color: rgb(137, 0, 0);
            }
        }

        &.share_button {
            display: flex;
            align-items: center;
            justify-content: space-evenly;

            .share_link {
                font-size: 20px;
                padding: 8px;

                background-color: black;

            }

            .copy_link {
                font-size: 20px;
            }
        }
    }

    td {
        background: #020202;
        font-family: Segoe UI, Frutiger, Frutiger Linotype, Helvetica Neue, Arial, sans-serif;
        font-size: 14px;

        .token_unit {
            font-size: 10px;
        }

        &.LOST {
            color: rgb(200, 0, 0);
            text-shadow: rgb(255, 138, 138) 0.2px 0.2px 0.2px;
        }

        &.WON {
            color: rgb(0, 160, 0);
            text-shadow: rgb(88, 255, 88) 0.2px 0.2px 0.2px;
        }

        &.DRAW {
            color: rgb(189, 189, 0);
            text-shadow: white 0.2px 0.2px 0.2px;
        }

    }

    /* 
    Max width before this PARTICULAR table gets nasty
    This query will take effect for any screen smaller than 760px
    and also iPads specifically.
    */

}

.challenged_game_list {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
    overflow-x: scroll;
    display: grid;
    margin: 0 auto;
    min-height: 300px;

    @include max-break-point(tablet-landscape) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include max-break-point(mobile-phone) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.friend_detail_card {
    display: flex;

    .img_nft {
        width: 45px;
        height: 45px;
        overflow: hidden;
        background-color: black;
        object-fit: cover;
        object-position: 100% 0;

        border-radius: 1px;
        border: 1px solid #673F26;
    }

    .frn_info {
        margin: 0px 10px;

        h3 {
            margin: 0px;
            margin-bottom: 4px;
            max-width: 180px;
            font-size: 1rem;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        p {
            white-space: nowrap;
            overflow: hidden;
            max-width: 120px;
            text-overflow: ellipsis;
            font-size: 0.9rem;
        }
    }

}

.friendList_cta {
    display: flex;
    align-items: c;

    button {
        margin-left: 10px;
    }
}