.property-img-carousel-wrapper {
    &.carousel-wrapper {
        margin: 0;
    }

    .selected-carousel {
        border: 1px solid var(--primary-color);
        border-radius: 15px;
    }

    .property-img {
        // margin-left: -50px;
        display: flex;
        align-items: center;
        flex-direction: column;

        .skeleton {
            position: relative;
            z-index: 0;
        }
    }

    .carousel-selected-img {
        width: 650px;
        height: 350px;
        object-fit: contain;
        max-width: 100%;
        margin: 0px;
        margin-top: -30px;
        border-radius: 15px;

        @include max-break-point(tablet) {
            margin-left: 30px;
        }
    }

    .image-diff-view-wrapper {
        margin-top: -50px;
        margin-left: 0px;
        margin-bottom: 15px;
        justify-content: center;
        flex-wrap: wrap;
        z-index: 3;

        position: relative;
        display: flex;

        .image-diff-view {
            display: flex;
            width: 45px;
            margin: 0px 10px;
            height: 55px !important;
            cursor: pointer;

            border-radius: 15px;

            &:hover {
                background: rgba(71, 71, 71, 0.4);
            }

            img {
                object-fit: contain;
            }
        }
    }
}