@import '../common/variables';

.side_menu_container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    backdrop-filter: blur(6px);
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.8);
    width: 520px;
    transition: 0.5s;

    .fill_container_side_menu {
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
    }

    &.close {
        width: 0px;
    }

    .button {
        $width: 30px;
        width: $width !important;
        position: absolute;
        cursor: pointer;
        height: 60px;
        background-color: rgba($color: #000000, $alpha: 0.8);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        // content: url('../../assets/icons/arrow-left.png');
        top: 150px;
        right: -$width;

        .icon {
            color: #DDBF62;
        }

        background-size: 1.25rem;

    }

    .side_menu_box {
        width: inherit;
        max-height: 75vh;
        overflow: hidden;
        overflow-y: scroll;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10rem;


    }

    .mobile_header {
        display: none;
    }

    @include max-break-point(mobile-phone) {
        width: 100vw;



        .button {
            display: none;
        }
    }
}