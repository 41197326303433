.emerald_button {
    padding: 0.5rem 0.625rem;
    margin: 0;
    color: black;
    border: 1px solid;
    font-size: 0.875rem;
    cursor: pointer;
    background: linear-gradient(180deg, #d5b452 -1.14%, #806d2a 90%);
    font-weight: 500;

    &:hover {

        background: linear-gradient(180deg, #DDBF62 -7.14%, #887635 100%);
    }

    &:disabled {
        background: linear-gradient(180deg, #242129 -7.14%, #242129 100%);
        color: rgba($color: white, $alpha: 0.4);
        border: none;
        cursor: default;

        &:hover {
            background: linear-gradient(180deg, #242129 -7.14%, #242129 100%);
        }
    }

    &.large {
        font-size: 1rem;
        font-weight: 500;
    }
}