.rps_container {
    // background-image: url('../../../assets/mini_games/rps/bg.png') !important;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;



    @include max-break-point(mobile-phone) {
        overflow: scroll;

        &.blur.overlay {
            height: auto !important;
            backdrop-filter: blur(40px) !important;


        }
    }

    .blur_for_bg {
        position: absolute;
        width: 100%;
        backdrop-filter: blur(20px) !important;
        height: 100vh;
    }

    .modal-wrapper {
        max-width: 1200px;
        width: 100%;
        // height: 775px;
        // min-height: 775px;
        min-height: 90vh;

        @include max-break-point(mobile-phone) {
            height: 100vh !important;
            min-height: 90vh !important;
            max-height: 100vh !important;
        }

        // height: 100vh;
    }

    .modal-main-decor {
        padding: 0;
        background: linear-gradient(180deg, #000 23.07%, rgba(0, 0, 0, 0.40) 100%), url('../../../assets/mini_games/rps/bg.png'), lightgray 50% / cover no-repeat;
        // background-image: ;
        background-repeat: no-repeat;
        background-size: cover;
        // border-top: none;
        margin: 0;
        // border-bottom: none;
        // height: 770px;
        // min-height: 770px;
        border: none;
        height: 90vh;
        min-height: 90vh;
        padding: 30px;

        .modal-decor-content,
        .modal-elements,
        .modal-children {
            height: inherit;
            max-width: inherit;
            padding: 0;
            margin: 0;

            .close-modal-btn {
                right: 30px;
                top: 10px;
            }
        }

        @include max-break-point(mobile-phone) {
            height: 100vh !important;
            min-height: 90vh !important;
            max-height: 100vh !important;


        }

    }

    .modal {
        max-height: inherit;
        overflow: hidden;
        height: inherit;
        padding: 0;
        margin: 0;

        @include max-break-point(mobile-phone) {
            overflow: scroll;
        }
    }



}

.rps_main {
    height: inherit;
    width: 100%;
    // background-color: red;
    display: flex;
    position: relative;
    justify-content: space-between;

    .dialogue_rps_main {
        position: absolute;
        top: 0;
        z-index: 999;
        left: 0;
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: space-around;
        backdrop-filter: blur(12px);

        .connect_option {
            margin-top: 10px;
        }

        .dia_container {
            width: 80%;

            @include max-break-point(mobile-phone) {
                width: 100%;

            }
        }
    }

    .battle_playing {
        position: absolute;
        top: 0;
        z-index: 999;
        left: 0;
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: space-around;
        backdrop-filter: blur(12px);

        .battle_middle {
            margin: 0 auto;
            width: 70%;
            height: 60%;

            @include max-break-point(tablet) {
                width: 90%;

            }

            @include max-break-point(mobile-phone) {
                width: 100%;


            }
        }
    }

    .mobile_play_gameButton {
        display: none;
    }

    .mobile_open_game_container {
        display: none;
    }

    @include max-break-point(tablet) {

        .mobile_open_game_container {
            display: none;
            position: absolute;
            bottom: 0;
            font-size: 44px;
            z-index: 99;
            transition: all 0.2s;
            left: 0;
            width: inherit;
            color: rgba(255, 255, 255, 0.7);

            &.opened {
                display: block;
                background-color: black;
            }

        }

        .mobile_play_gameButton {
            display: block;
            position: absolute;
            bottom: 30px;
            font-size: 44px;
            z-index: 9999;
            right: 5px;
            color: rgba(255, 255, 255, 0.7);
            transition: all 0.2s;

            .FaCirclePlus {
                transition: all 0.2s;

                &.selected {
                    transform: rotate(45deg);
                }
            }

        }


    }

    .rps_left_container {
        position: relative;
        width: 60%;
        height: 100vh;
        overflow: scroll;


        @include max-break-point(tablet) {
            width: 100%;
        }



        // height: 88vh;
        // max-height: 88vh;

        .intro_section {
            background-color: rgba(0, 0, 0, 0.7);
            border-radius: 10px;
            display: flex;
            // align-items: center;
            color: white;
            // padding: 20px;

            img {
                height: 200px;
                width: auto;

                @include max-break-point(mobile-phone) {
                    display: none;
                }
            }

            .intro_section_left {
                padding: 20px;

                .intro_section_title {
                    font-size: 23px;
                    font-style: normal;
                    font-family: Big Shoulders Display;
                    font-weight: bold;
                    line-height: normal;
                    letter-spacing: 0.4px;
                    color: rgba(255, 255, 255, 0.9);
                    margin-bottom: 12px;

                }

                .intro_section_description {
                    color: rgba(255, 255, 255, 0.75);
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 145%;
                    font-family: Segoe UI, Frutiger, Frutiger Linotype, Helvetica Neue, Arial, sans-serif;
                    // font-family: Segoe UI, Frutiger, Frutiger Linotype, Helvetica Neue, Arial, sans-serif;


                }
            }

        }

        .container_menu {
            color: #ffffffd0;
            font-weight: bold;
            display: flex;
            overflow: scroll;
            cursor: pointer !important;

            div {
                margin: 10px 10px;
                margin-bottom: 5px;
                position: relative;
                padding-bottom: 5px;
                white-space: nowrap;

                &:hover {
                    border-bottom: 1px solid rgba($color: #ffffff, $alpha: 0.4);
                    color: #ffffffe6;

                }

                &.selected {
                    color: white;
                    border-bottom: 1px solid yellow;
                }
            }

            .mobile_logs {
                display: none;
            }

            @include max-break-point(tablet) {

                .mobile_logs {
                    display: block !important;
                }

            }

            // .mobile_open_game {}



        }

        .action_container {
            margin-top: 10px;
            background-color: rgba(0, 0, 0, 0);

            .title {
                color: #FFF;

                font-family: Big Shoulders Display;
                font-size: 20px;
                font-style: normal;
                font-weight: 800;
                line-height: normal;
                letter-spacing: 0.4px;


            }

            .open_game_description {
                color: #DEDEDE;
                font-family: Manrope;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 10px;

            }

        }


    }

    .rps_right_container {

        width: 37%;
        height: 100%;
        max-height: 100vh;
        color: white;
        // background-color: red;
        background-color: rgba(0, 0, 0, 0.7);

        @include max-break-point(tablet) {
            display: none;
        }


    }
}

.no_game {
    color: white;
    text-align: center;
    padding: 1rem;
    background-color: red($color: #000000);
    font-family: Big Shoulders Display;
    font-size: 22px;
    font-style: normal;
    letter-spacing: .2px;
    font-weight: 800;
}

.clickable {
    cursor: pointer !important;

    &:hover {
        text-decoration: underline;
        color: #8bebf5;
    }
}

.inlink {
    color: #1aaaba !important;
    cursor: pointer !important;

    &:hover {
        text-decoration: underline;
        color: #8bebf5;
    }
}

.outlink {
    color: #1aaaba !important;

}