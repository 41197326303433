.modal-main-decor {
  border: 2px solid #aa9b46;
  position: relative;
  padding: 15px;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(12px);

  &.viewer3d {
    background: #b2ced8;
  }
}

.modal-main-decor .modal-decor-edge-tl,
.modal-main-decor .modal-decor-edge-tr,
.modal-main-decor .modal-decor-edge-bl,
.modal-main-decor .modal-decor-edge-br {
  position: absolute;
  padding: 4px;
  z-index: 99;
}

.modal-main-decor .modal-decor-edge-tl {
  top: 0;
  left: 0;
}

.modal-main-decor .modal-decor-edge-tr {
  top: 0;
  right: 0;
  transform: rotate(90deg);
}

.modal-main-decor .modal-decor-edge-bl {
  bottom: 0;
  left: 0;
  transform: rotate(270deg);
}

.modal-main-decor .modal-decor-edge-br {
  bottom: 0;
  right: 0;
  transform: rotate(180deg);
}

.modal-main-decor .modal-decor-content {
  margin: 5px;
}