.map_type_selection_container {
    position: absolute;
    bottom: 1.25rem;
    right: 1.25rem;
    z-index: 5;

    @include max-break-point(mobile-phone) {
        bottom: 8rem !important;
    }


    .map_types_option {
        position: relative;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        font-size: 1.25rem;
        background-color: #242129;
        color: white;

        @include max-break-point(tablet-landscape) {
            display: block;
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin: -0.188rem;
            border-radius: inherit;
            background: linear-gradient(180deg, #DDBF62 -7.14%, #887635 100%);
        }

        .type_item {
            padding: 8px 16px;
            cursor: pointer;

            &:hover {
                background-color: #333137;
            }

            &.selected {
                background-color: #DDBF62;
                color: #242129;
                font-weight: 600;
                background: linear-gradient(180deg, #DDBF62 -7.14%, #887635 100%);

            }
        }
    }
}