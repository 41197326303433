.rps_log {
    padding: 20px;
    // backdrop-filter: blur(12px);
    filter: drop-shadow(0.1px 0.1px 0.1px rgb(255, 255, 255));
    color: #FFF;
    height: 100%;
    max-height: calc(100%);

    .menu_items {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        div {
            color: #FFF;
            font-size: 18px;
            font-style: normal;
            margin: 0px 10px 0px 0px;
            cursor: pointer;
            letter-spacing: 0.5px;
            font-weight: bold;
            line-height: normal;


        }
    }

    .logs {
        overflow: hidden;
        overflow-y: scroll;
        // background-color: red;
        max-height: calc(100% - 100px);


        .log_message {

            color: #FFF;
            margin: 20px 0px;

            &.self {
                &.lost {
                    // background: rgb(87, 0, 29);
                    // background: linear-gradient(0deg, rgba(87, 0, 29, 0.1) 0%, rgba(121, 9, 9, 0.1) 35%, rgba(0, 212, 255, 0) 100%);
                }
            }

            .message_details {
                display: flex;
                align-items: center;
                font-size: 12px;
                margin-bottom: 5px;
                // justify-content: space-between;
            }

            .time {
                margin-right: 4px;
                color: #ffffff97;

            }

            .txHash {
                color: #1aaaba;
                text-decoration: none;
                margin-left: 4px;

                &:hover {
                    text-decoration: underline;

                }
            }

            .message {
                // font-family: Manrope;
                font-size: 14px;
                font-style: normal;
                letter-spacing: 0.5px;
                font-family: Segoe UI, Frutiger, Frutiger Linotype, Helvetica Neue, Arial, sans-serif;
                font-weight: 400;
                color: rgba($color: #ffffff, $alpha: 0.8);
                line-height: 18px;
            }

        }
    }


}