@import url('https://fonts.googleapis.com/css2?family=Big+Shoulders+Display:wght@100;200;300;400;500;600;800;900&family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
@import './common/mixins';

:root {
    font-size: 16px;

    @include max-break-point(tablet) {
        font-size: 14px
    }

    @include max-break-point(mobile-phone) {
        font-size: 12px
    }
}

@import './leaflet/districtLayer.scss';
@import './leaflet/mintedLandsLayers.scss';
@import './common/mapTypes.scss';
@import './common/sideMenu.scss';
@import "./common/profilePicture.scss";
@import './common/toggleSwitch.scss';
@import './common/toggleComponent.scss';
@import './common/button.scss';
@import './common/header.scss';
@import './common/tooltip.scss';
@import './common/propsCarousel.scss';
@import './common/dropdown.scss';
@import './common/socialsComponent.scss';
@import './modal/modal.scss';
@import './modal/profile.scss';
@import './miniGames/index.scss';
@import "./modal/minigame.scss";

body {
    margin: 0;
    width: 100%;
    height: 100vh;
    padding: 0 !important;
    margin: 0 !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // font-family: 'Big Shoulders Display', cursive;
    // font-family: 'Manrope', sans-serif;
    font-family: Lintel;

}

.leaflet_emerald_map_container {
    width: 100%;
    height: 100vh;

}

#root {
    width: 100%;
    height: 100vh;
}

.main_container {
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.leaflet-div-icon {
    background-color: transparent !important;
    border: none !important;
}

.leaflet-control-zoom {
    display: none;

}

/* width */
::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 50%;
    background: #DDBF62;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.leaflet-control-rotate {
    display: none !important;
}

.leaflet-control-zoom {
    display: none !important;
}

.blur_it {
    -webkit-filter: blur(2px) !important;
    -moz-filter: blur(2px) !important;
    -o-filter: blur(2px) !important;
    -ms-filter: blur(2px) !important;
    filter: blur(2px) !important;
}

.loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-around;
    backdrop-filter: blur(12px);

    .lds-circle {
        display: inline-block;
        transform: translateZ(1px);
    }

    .message_loading {
        font-size: 18px;
        font-weight: bold;
    }

    .lds-circle>div {
        display: inline-block;
        width: 64px;
        height: 64px;
        margin: 8px;
        border-radius: 50%;
        background: #fff;
        animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }

    @keyframes lds-circle {

        0%,
        100% {
            animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
        }

        0% {
            transform: rotateY(0deg);
        }

        50% {
            transform: rotateY(1800deg);
            animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
        }

        100% {
            transform: rotateY(3600deg);
        }
    }
}