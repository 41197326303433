* {
    --mobile_width: 650px;
}

html {
    --white: #fff;
    --secondary-color: #bebebe;
    --dm-san: 'DM Sans', sans-serif;
    --manrope: 'Manrope', sans-serif;
    --cinzel: 'Cinzel', serif;
    --primary-color: #aa9b46;
    --primary-blue: #079b9d;
}

@font-face {
    font-family: Lintel;
    src: url(../../assets/fonts/Lintel-Regular.ttf);
}